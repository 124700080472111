/**
 * Determines whether an element should be visible based on its type, legal form, and visibility rules.
 * @param {string} type - The type of the entity ('company' or 'individual').
 * @param {Object} legalForm - An object containing legal form details of the entity.
 * @param {string} legalForm.companyType - The type of company.
 * @param {string} legalForm.bookkeeping - The bookkeeping method used by the entity.
 * @param {string} legalForm.annualReport - The type of annual report associated with the entity.
 * @param {Object} visibility - An object defining the visibility criteria for the element.
 * @param {Array<string>} [visibility.companyTypes] - A list of allowed company types for visibility.
 * @param {Array<string>} [visibility.bookkeeping] - A list of allowed bookkeeping methods for visibility.
 * @param {Array<string>} [visibility.annualReport] - A list of allowed annual report types for visibility.
 * @returns {boolean} - Returns `true` if the element should be visible; otherwise, `false`.
 */
export function isElementVisible(type, legalForm, visibility) {
  if (!legalForm || !visibility || !type) return true;

  const { companyType, bookkeeping, annualReport } = legalForm;

  if (type === 'company' && visibility.companyTypes && !visibility.companyTypes.includes(companyType)) return false;

  if (type === 'individual') {
    if (visibility.companyTypes && !visibility.companyTypes.includes('DE_Privatperson')) return false;
    return true;
  }

  if (visibility.bookkeeping && !visibility.bookkeeping.includes(bookkeeping)) return false;
  if (visibility.annualReport && !visibility.annualReport.includes(annualReport)) return false;

  return true;
}

/**
 * Determines whether a company's legal form requires migration.
 * @param {object} company - Company data object.
 * @param {string} company.type - The type of company, e.g., 'individual' or 'company'.
 * @param {object} company.legalForm - Legal form details of the company.
 * @param {string} company.legalForm.companyType - The company's type in the legal form.
 * @param {string} company.legalForm.annualReport - The annual report status of the legal form.
 * @param {string} company.legalForm.bookkeeping - The bookkeeping status of the legal form.
 * @param {boolean} company.legalForm.manualMigration - Indicates whether manual migration has been completed.
 * @returns {boolean} `true` if the company requires legal form migration; otherwise `false`.
 */
export const forceLegalFormMigration = (company) => {
  if (company.type === 'individual') return false;
  if (!company.legalForm || !company.legalForm.companyType || !company.legalForm.annualReport || !company.legalForm.bookkeeping || !company.legalForm.manualMigration) return true;
  return false;
};
