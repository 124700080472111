/* eslint-disable prettier/prettier */

const companyTypes = [
  {
    "country": "DE",
    "companyType": "Privatperson",
    "abbreviation": null,
    "annualReport": [],
    "match": [
      "privatePerson"
    ]
  },
  {
    "country": "DE",
    "companyType": "Einzelkaufmann",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfAccountsSingle",
      "cashBasisAccountingSingle"
    ]
  },
  {
    "country": "DE",
    "companyType": "Freier Beruf",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "cashBasisAccountingSingle",
      "cashBasisAccountingCapital"
    ]
  },
  {
    "country": "DE",
    "companyType": "Land- und forstwirtschaftliches Einzelunternehmen",
    "abbreviation": "LuF Einzelunternehmen",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfAccountsSingle",
      "cashBasisAccountingSingle"
    ]
  },
  {
    "country": "DE",
    "companyType": "Offene Handelsgesellschaft",
    "abbreviation": "OHG",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsOther"
    ]
  },
  {
    "country": "DE",
    "companyType": "Kommanditgesellschaft",
    "abbreviation": "KG",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsOther"
    ]
  },
  {
    "country": "DE",
    "companyType": "Gesellschaft bürgerlichen Rechts",
    "abbreviation": "GbR",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfAccountsOther",
      "cashBasisAccountingCapital"
    ]
  },
  {
    "country": "DE",
    "companyType": "GmbH & Co. KG",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Gesellschaft mit beschränkter Haftung",
    "abbreviation": "GmbH",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbH"
    ]
  },
  {
    "country": "DE",
    "companyType": "Aktiengesellschaft",
    "abbreviation": "AG",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Kommanditgesellschaft auf Aktien",
    "abbreviation": "KGaA",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Eingetragene Genossenschaft",
    "abbreviation": "eG",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Bergrechtliche Gewerkschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Eingetragener Verein",
    "abbreviation": "e.V.",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfUnion",
      "cashBasisAccountingUnion"
    ]
  },
  {
    "country": "DE",
    "companyType": "Stiftung des Privatrechts",
    "abbreviation": "Stiftung des PrivatR",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Kleingewerbe",
    "abbreviation": null,
    "annualReport": [
      "EUR"
    ],
    "match": [
      "cashBasisAccountingSingle"
    ]
  },
  {
    "country": "DE",
    "companyType": "Partnerschaftsgesellschaft",
    "abbreviation": "PartG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "cashBasisAccountingCapital"
    ]
  },
  {
    "country": "DE",
    "companyType": "AG & Co. KG",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Komplementär-GmbH",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbH"
    ]
  },
  {
    "country": "DE",
    "companyType": "Körperschaft des öffentlichen Rechts",
    "abbreviation": "KdöR",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Sonstige juristische Person des privaten Rechts",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Gemeinschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Erbengemeinschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Sonstige & Co. KG",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Sonstige & Co. OHG",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Nicht eingetragener Verein",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Anstalt des öffentlichen Rechts",
    "abbreviation": "AöR",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Gewerkschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Limited & Co. KG",
    "abbreviation": "Ltd. & Co. KG",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "OHG & Co. KG",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Politische Partei",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Reederei",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Seerechtliche Gesellschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Stiftung des öffentlichen Rechts",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Verband",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Versicherungsverein auf Gegenseitigkeit",
    "abbreviation": "VVaG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Wohnungseigentümergemeinschaft",
    "abbreviation": "WEG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Unternehmergesellschaft (haftungsbeschränkt) (UG)",
    "abbreviation": "UG (haftungsbeschränkt)",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsUG"
    ]
  },
  {
    "country": "DE",
    "companyType": "GmbH & Co. OHG",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Aktiengesellschaft & Co. OHG",
    "abbreviation": "AG & Co. OHG",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Grundstücksgemeinschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Bruchteilsgemeinschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Atypische stille Gesellschaft",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "GmbH & Co. KGaA",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "GmbH i.G.",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "gemeinnützige GmbH",
    "abbreviation": "gGmbH",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Unternehmerges. (haftungsbeschr.) & Co. KG",
    "abbreviation": "UG & Co. KG",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbHAndCoKg"
    ]
  },
  {
    "country": "DE",
    "companyType": "Jur. Person kirchl. Rechts",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "GmbH I.L.",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbH"
    ]
  },
  {
    "country": "DE",
    "companyType": "Versicherungsverein auf Aktien",
    "abbreviation": "VVaA",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Behörde, Amt",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Anstalt d. priv. Rechts",
    "abbreviation": "Anstalt",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Partnerschaftsgesellschaft mit beschränkter Berufshaftung",
    "abbreviation": "PartG mbB",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "cashBasisAccountingCapital"
    ]
  },
  {
    "country": "DE",
    "companyType": "Investmentkommanditgesellschaft",
    "abbreviation": "InvKG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "gemeinnützige Unternehmergesellschaft (haftungsbeschränkt)",
    "abbreviation": "gUG (haftungsbeschränkt)",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "DE",
    "companyType": "Stiftung & Co. KG",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "FI",
    "companyType": "Osakeyhtiö",
    "abbreviation": "Oy",
    "annualReport": null,
    "match": null
  },
  {
    "country": "GB",
    "companyType": "Limited",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "GB",
    "companyType": "Limited partnership",
    "abbreviation": "LP",
    "annualReport": null,
    "match": null
  },
  {
    "country": "GB",
    "companyType": "public company limited by shares",
    "abbreviation": "plc.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "GB",
    "companyType": "private company limited by shares",
    "abbreviation": "Ltd.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "GB",
    "companyType": "Limited Liability Company",
    "abbreviation": "LLC",
    "annualReport": null,
    "match": null
  },
  {
    "country": "LI",
    "companyType": "Treuunternehmen reg.",
    "abbreviation": "Treuhand reg.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Einzelunternehmer",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "cashBasisAccountingSingle"
    ]
  },
  {
    "country": "AT",
    "companyType": "Freier Beruf",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Land- und Forstwirtschaftliches Einzelunternehmen",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Kleingewerbe",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Gesellschaft mit beschränkter Haftung",
    "abbreviation": "GmbH",
    "annualReport": [
      "BIL"
    ],
    "match": [
      "balanceOfAccountsGmbH"
    ]
  },
  {
    "country": "AT",
    "companyType": "Offene Gesellschaft",
    "abbreviation": "OG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfAccountsOther"
    ]
  },
  {
    "country": "AT",
    "companyType": "Gesellschaft  bürgerlichen Rechts",
    "abbreviation": "GesbR",
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Arbeitsgemeinschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Aktiengesellschaft",
    "abbreviation": "AG",
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Kommanditgesellschaft",
    "abbreviation": "KG",
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "balanceOfAccountsOther"
    ]
  },
  {
    "country": "AT",
    "companyType": "AG & Co. KG",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Genossenschaft beschränkte Haftung",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Genossenschaft unbeschränkte Haftung",
    "abbreviation": null,
    "annualReport": [
      "BIL"
    ],
    "match": null
  },
  {
    "country": "AT",
    "companyType": "GmbH & Co. KG",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Verein",
    "abbreviation": null,
    "annualReport": [
      "BIL",
      "EUR"
    ],
    "match": [
      "cashBasisAccountingUnion"
    ]
  },
  {
    "country": "AT",
    "companyType": "Stiftung altes Recht",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Privatstiftung",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Grundstücksgemeinschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Atypische stille Gesellschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Miteigentümerschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Sparkasse",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Körperschaft öffentlichen rechts",
    "abbreviation": "KöR",
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "Stille Gesellschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "GmbH & Still",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "AT",
    "companyType": "AG & Still",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": "SE",
    "companyType": "Aktiebolag",
    "abbreviation": "AB",
    "annualReport": null,
    "match": null
  },
  {
    "country": "ES",
    "companyType": "Sociedad Anónima",
    "abbreviation": "S.A.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "ES",
    "companyType": "Sociedad de Responsibilidad Limitada",
    "abbreviation": "S.R.L.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "ES",
    "companyType": "Sociedad Limitada",
    "abbreviation": "S.L.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "CZ",
    "companyType": "Spolecnost s rucenim omezenym",
    "abbreviation": "s.r.o.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "US",
    "companyType": "Incorporated",
    "abbreviation": "Inc.",
    "annualReport": null,
    "match": null
  },
  {
    "country": "US",
    "companyType": "Limited Liability Partnership",
    "abbreviation": "LLP",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Sonstige Personengesellschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Sonstige",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Europäische Aktiengesellschaft (mit Vorstand/Aufsichtsrat)",
    "abbreviation": "SE (dualistisch)",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Sonstige Kapitalgesellschaft",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Europäische Aktiengesellschaft (mit Verwaltungsrat)",
    "abbreviation": "SE (monistisch)",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Europäische Genossenschaft (mit Verwaltungsrat)",
    "abbreviation": "SCE (monistisch)",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Europäische Genossenschaft (mit Vorstand/Aufsichtsrat)",
    "abbreviation": "SCE (dualistisch)",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Europäische wirtschaftliche Interessenvereinigung",
    "abbreviation": "EWIV",
    "annualReport": null,
    "match": null
  },
  {
    "country": null,
    "companyType": "Sonstiges Einzelunternehmen",
    "abbreviation": null,
    "annualReport": null,
    "match": null
  }
];

export default companyTypes;
