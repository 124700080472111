import companyTypes from './companyTypes';

/**
 * Utility class to manage and retrieve company type information.
 * @class CompanyTypeUtility
 */
export class CompanyTypeUtility {
  /**
   * Initializes the utility with company type data.
   * @constructor
   */
  constructor() {
    this.data = companyTypes;
  }

  /**
   * Retrieves a company type object based on the key.
   * The key format is `country_companyType`, where `country` can be 'null'.
   * @param {string} key - The key representing a country and company type.
   * @returns {Object|null} The matching company type object, or `null` if not found.
   */
  getObjectByKey(key) {
    const [country, companyType] = key.split('_');
    const normalizedCountry = country === 'null' ? null : country;

    return this.data.find((item) => item.companyType === companyType && item.country === normalizedCountry) || null;
  }

  /**
   * Retrieves a specific property for a given company type.
   * @param {string} companyType - The company type.
   * @param {string} property - The property to retrieve.
   * @returns {any|null} The value of the property, or `null` if not found.
   */
  getProperty(companyType, property) {
    const form = this.data.find((item) => item.companyType === companyType);
    if (form) {
      return form[property] || null;
    }
    return null;
  }

  /**
   * Retrieves the abbreviation for a given company type.
   * @param {string} companyType - The company type.
   * @returns {string|null} The abbreviation, or `null` if not found.
   */
  getAbbreviation(companyType) {
    return this.getProperty(companyType, 'abbreviation');
  }

  /**
   * Retrieves the annual report types for a given company type.
   * @param {string} companyType - The company type.
   * @returns {Array<string>|null} The annual report types, or `null` if not found.
   */
  getAnnualReport(companyType) {
    return this.getProperty(companyType, 'annualReport');
  }

  /**
   * Retrieves the country for a given company type.
   * @param {string} companyType - The company type.
   * @returns {Array<string>|null} The country, or `null` if not found.
   */
  getCountry(companyType) {
    return this.getProperty(companyType, 'country');
  }

  /**
   * Retrieves the match property for a given company type.
   * @param {string} companyType - The company type.
   * @returns {Array<string>|null} The match property, or `null` if not found.
   */
  getMatch(companyType) {
    return this.getProperty(companyType, 'match');
  }

  /**
   * Retrieves a list of company types for a given country.
   * @param {string} country - The country code to filter by.
   * @returns {Array<string>} A list of company types in the specified country.
   */
  getCompanyTypesByCountry(country) {
    const filteredForms = this.data.filter((item) => item.country === country);
    return filteredForms.map((item) => item.companyType);
  }

  /**
   * Retrieves a list company types by a legacy match name.
   * @param {string} matchString - The legacy match string to search for.
   * @returns {Array<Object>} A list of company types that match the given string.
   */
  getCompanyTypesByLegacyName(matchString) {
    return this.data.filter((item) => item.match?.includes(matchString)) || [];
  }

  /**
   * Retrieves all company types with key, company type, country, and abbreviation.
   * @returns {Array<Object>} A list of objects containing:
   * - `key` (string): A unique identifier combining the country and company type.
   * - `companyType` (string): The company type.
   * - `country` (string): The country code associated with the company type.
   * - `abbreviation` (string): The abbreviation of the company type.
   */
  getAll() {
    return this.data.map((item) => {
      return {
        key: `${item.country}_${item.companyType}`,
        companyType: item.companyType,
        country: item.country,
        abbreviation: item.abbreviation,
      };
    });
  }
}
